import React from 'react'
import { useTranslation } from 'react-i18next'
import { Store } from 'Store'
import * as Form from 'components/Form'
import Button from 'components/Button'
import BottomBar from 'components/BottomBar'
import CarSummary from 'components/CarSummary'

import withTheme from 'hoc/withTheme'
import withForm from 'components/Form/Helpers/FormHOC'
import transDomain from './translations/index.translations'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

function Step3 ({ theme, isLoading, values, handleFieldChange, initialValues }) {
    const { state } = React.useContext(Store)
    const { t } = useTranslation(transDomain)

    const {
        newVehicleMake,
        newVehicleModel,
        newVehicleModelYear,
        newVehicleModelColor
    } = state.lead.fields || initialValues

    const model = React.useMemo(() => {
        return state.campaign.models.find(m => {
            return (newVehicleMake === m.make && m.name === newVehicleModel && parseInt(newVehicleModelYear) === parseInt(m.year || 0))
        })
    }, [state.campaign.models, newVehicleMake, newVehicleModel, newVehicleModelYear])

    const hotButtons = React.useMemo(() => (values.hotButtons || []).map(i => i.toLowerCase()), [ values.hotButtons ])

    const handleBtnOnclick = value => {
        const values = hotButtons
        let a
        a = values.indexOf(value) !== -1
            ? values.filter(item => item !== value)
            : [...values, value].splice(-2)
        handleFieldChange('hotButtons', a)
    }

    if (state.campaign.locations.length === 1 && !values.storeLocationId) {
        handleFieldChange('storeLocationId', state.campaign.locations[0].id)
    }

    const salesReps = React.useMemo((): { [string]: string } => {
        const selectedStoreLocation = state.campaign.locations.find(location => location.id === values.storeLocationId)
        if (!selectedStoreLocation) return {}

        return {
            ...selectedStoreLocation.salesReps.reduce((salesReps, rep) => ({ [rep.name]: rep.name, ...salesReps }), {}),
            'none': t('field.salesperson.options.none.label')
        }
    }, [values.storeLocationId, state.campaign.locations, t])

    const voucherValue = state.campaign.incentives.voucher.type !== 'variable' ? state.campaign.incentives.voucher?.value : model?.voucherValue
    return (
        <>
            <div className={styles.layoutWrapper}>
                <div className={styles.heading} style={{
                    color: theme.titleColor
                }}>
                    {t('heading')}
                </div>

                <div className={styles.stepContainer}>
                    <div className={styles.sideBar}>
                        <CarSummary
                            startingPrice={model?.startingPrice}
                            name={model?.name}
                            make={model?.make}
                            segment={model?.segment}
                            year={model?.year}
                            image={model?.images.front_34.m}
                            voucherValue={voucherValue}
                            colorProp={newVehicleModelColor}
                        />
                    </div>
                    <div className={styles.formContainer}>
                        <div className={styles.buttonGroup}>
                            <Form.Row>
                                <Form.RowColumns>
                                    <Form.RowColumn>
                                        <Form.Field noBorder label={t('field.hotButtons.label')}>
                                            <Form.RowColumns>
                                                {[
                                                    'safety',
                                                    'dependability',
                                                    'performance',
                                                    'appearance',
                                                    'economy',
                                                    'comfort'
                                                ].map(choise => (
                                                    <Form.RowColumn
                                                        className={styles.rowWrapper}
                                                        size={0.5}
                                                        padding={'1em 0.5em 0.1em 0.5em'}
                                                        key={choise}>
                                                        <Button
                                                            onClick={() => handleBtnOnclick(choise)}
                                                            style={(hotButtons || []).indexOf(choise) !== -1 ? theme.activeButton : theme.button}>
                                                            {t(`field.hotButtons.choice.${choise}`)}
                                                        </Button>
                                                    </Form.RowColumn>
                                                ))}
                                                <input
                                                    tabIndex={-1}
                                                    type='text'
                                                    value={hotButtons.length === 2 ? `${hotButtons.length}` : ''}
                                                    onChange={() => null}
                                                    required={true}
                                                    style={{
                                                        opacity: 0,
                                                        position: 'absolute',
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        height: '100%',
                                                        zIndex: 0,
                                                        color: 'transparent',
                                                        caretColor: 'transparent',
                                                    }}
                                                />
                                            </Form.RowColumns>
                                        </Form.Field>
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>
                        </div>
                        <div className={styles.dropdowns}>
                            <Form.Row>
                                <Form.RowColumn>
                                    <Form.RowColumns>
                                        <Form.Field>
                                            <Form.Dropdown
                                                placeholder={t('marketPlace.placeholder')}
                                                options={['immediately', 'lessThanAWeek', 'aFewWeeks', 'aFewMonths']}
                                                optionFormat={'field.timeline.options.%name%'}
                                                trans={t}
                                                onChange={value => handleFieldChange('timeline', value)}
                                                defaultValue={values.timeline}
                                            />
                                        </Form.Field>
                                    </Form.RowColumns>
                                </Form.RowColumn>
                            </Form.Row>
                            <Form.Row>
                                <Form.RowColumn>
                                    <Form.RowColumns>
                                        <Form.Field>
                                            <Form.Dropdown
                                                options={salesReps}
                                                onChange={value => handleFieldChange('salesperson', value)}
                                                defaultValue={values.salesperson}
                                                placeholder={t('associated.placeholder')}
                                                required={false}
                                            />
                                        </Form.Field>
                                    </Form.RowColumns>
                                </Form.RowColumn>
                            </Form.Row>
                        </div>
                    </div>
                </div>
            </div>
            <BottomBar
                step={2}
                isLoading={isLoading}
            />
        </>
    )
}

export default withForm()(withTheme(supportedThemes)(Step3))
